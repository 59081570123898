import '../../app-configuration/app-configuration.js';
import { KatapultPageElement, html } from '../../mixins/katapult-page-element.js';
import { html as litHtml } from 'lit';
import { timeOut } from '@polymer/polymer/lib/utils/async.js';
import { Debouncer } from '@polymer/polymer/lib/utils/debounce.js';
import * as Comlink from 'comlink/dist/esm/comlink';
import pluralize from 'pluralize-esm';
import '@polymer/paper-styles/shadow.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-toast/paper-toast.js';
import '@polymer/paper-dialog-scrollable/paper-dialog-scrollable.js';
import '@polymer/paper-menu-button/paper-menu-button.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
import '@polymer/iron-list/iron-list.js';
import '@polymer/iron-collapse/iron-collapse.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/iron-icons/iron-icons.js';
import '@polymer/iron-icons/av-icons.js';
import '@polymer/iron-icons/hardware-icons.js';
import '@polymer/iron-icons/image-icons.js';
import '@polymer/iron-icons/social-icons.js';
import '@polymer/iron-icons/communication-icons.js';
import '@polymer/iron-icons/editor-icons.js';
import '@polymer/neon-animation/neon-animation.js';
import '@polymer/iron-collapse/iron-collapse.js';
import '@katapult/pmp-simple-date-input/pmp-simple-date-input.js';
import '@polymer/paper-tooltip/paper-tooltip.js';
import '@polymer/paper-item/paper-icon-item.js';
import 'iron-a11y-keys/iron-a11y-keys.js';
import '@polymer/paper-spinner/paper-spinner-lite.js';
import '@katapult/s-circle-progress/s-circle-progress.js';
import '../katapult-auth/katapult-auth.js';
import '../pole-application-sign-up-form/pole-application-sign-up-form.js';
import '../katapult-maps-desktop/katapult-tool-panel.js';
import '../paper-table/paper-table.js';
import '../bind-path/bind-path.js';
import '../login-chip/login-chip.js';
import '../user-chip/user-chip.js';
import '../highlight-text/highlight-text.js';
import '../input-element/input-element.js';
import '../katapult-firebase-worker/katapult-firebase-worker.js';
import '../katapult-toolbar/katapult-toolbar.js';
import '../katapult-drop-down/katapult-multi-drop-down.js';
import '../style-modules/paper-dialog-style.js';
import '../katapult-elements/katapult-button.js';
import '../katapult-elements/katapult-icon.js';
import '../katapult-dialog-legacy/katapult-dialog-legacy';
import './app-filter-input.js';
import '../style-modules/paper-tooltip-style.js';
import '../style-modules/paper-menu-button-style.js';
import '../style-modules/flex.js';
import '../pole-application/pole-application-style.js';
import '../app-timeline-editor/app-timeline-editor.js';
import '../../js/open-source/zipjs/zip.js';
import '../../js/open-source/zipjs/zip-ext.js';
import { Download } from '../../modules/Download.js';
import { ToArray } from '../../modules/ToArray.js';
import { CreateDraft } from '../../modules/CreateDraft.js';
import { NotifyResizable } from '../../modules/NotifyResizable.js';
import { Path } from '../../modules/Path.js';
import { DaysBetween, FormatDate } from '../../modules/Date.js';
import { SnakeCase } from '../../modules/SnakeCase.js';
import { AppExport } from '../../modules/AppExport.js';
import { UpdateJobPermissions } from '../../modules/UpdateJobPermissions.js';
import { CamelCase } from '../../modules/CamelCase.js';
import { DeepEqual } from '../../modules/DeepEqual.js';
import { KatapultDialog } from '../katapult-elements/katapult-dialog.js';
import { addFlagListener, removeFlagListener } from '../../modules/FeatureFlags.js';
import { DateTime } from 'luxon';
import { getActiveTimelineStageProgress, getTimelineProgress } from '../../modules/PoleApplication.js';
import { checkIsDevelopmentOrigin } from '../../modules/CheckOrigin.js';

/*global k, Polymer, firebase */
class PoleApplicationOverview extends KatapultPageElement {
  static get template() {
    return html`
      <style include="pole-application-style paper-tooltip-style paper-menu-button-style flex">
        :host {
          height: 100%;
          font-family: Roboto;
        }
        [hidden] {
          display: none !important;
        }
        [invisible] {
          opacity: 0;
        }
        #header {
          position: relative;
          height: 64px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px;
          box-sizing: border-box;
          background-color: var(--primary-color);
          flex-shrink: 0;
          @apply(--shadow-elevation-2dp);
        }
        #logo {
          height: 100%;
        }
        katapult-button:not([iconOnly]) {
          min-width: 75px;
        }
        paper-table, iron-list {
          flex-grow: 1;
          min-height: 0;
        }
        #appList {
          overflow-y: scroll;
        }
        .appRow:hover {
          background-color: var(--paper-grey-100);
        }
        .appRow:not(:last-of-type) {
          border-bottom: 1px solid var(--paper-grey-100);
        }
        paper-table-scroll > paper-row:not(:last-of-type) {
          border-bottom: 1px solid var(--paper-grey-300);
        }
        paper-cell:first-of-type {
          max-width: 48px;
          justify-content: center;
        }
        paper-cell[clickable]:hover {
          cursor: pointer;
        }
        paper-cell.button {
          max-width: 64px;
          padding: 0;
          justify-content: center;
        }
        iron-icon {
          color: var(--primary-color-text-color-faded);
        }
        #overviewHeader {
          font-size: 10pt;
          font-weight: bolder;
          background-color: var(--primary-color);
          color: var(--primary-color-text-color);
        }
        #overviewHeader iron-icon {
          color: var(--primary-color-text-color);
        }
        #overviewHeader paper-cell:not(first-of-type):hover {
          cursor: pointer;
        }
        #pageElement {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        paper-input, pmp-simple-date-input, drop-down {
          --primary-color: var(--secondary-color);
        }
        .statusIcon {
          padding: 8px;
          background-color: var(--paper-grey-300);
          border-radius: 100%;
          /* @apply(--shadow-elevation-2dp); */
        }
        highlight-text {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* number of lines to show */
          line-height: 18px;        /* fallback */
          max-height: 36px;       /* fallback */
          --filter: {
            color: var(--paper-grey-600);
          };
          --match: {
            color: var(--primary-text-color);
          };
          --highlight: {
            background-color: var(--secondary-color);
            color: var(--secondary-color-text-color);
            padding: 2px;
          };
        }
        .iconButton {
          padding-right: 0px;
          transition: transform 0.3s;
        }
        @media (min-width: 600px) {
          #regionalAdminDialog {
            width: 600px;
          }
        }
        user-chip {
          padding: 0;
          --user-chip-profile: {
            height: 24px;
            width: 24px;
            font-size: 11pt;
          };
          --user-chip-name: {
            font-size: 10pt;
            margin-left: 8px;
            margin-right: 4px;
          };
        }
        .userChipWrapper {
          padding: 4px;
          margin: 2px;
          border-radius: 40px;
          background-color: var(--paper-grey-200);
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
        }
        .userChipWrapper katapult-button {
          height: 24px;
          width: 24px;
          padding: 2px;
        }
        .centerTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position:relative;
        }
        .centerTitle span {
          font-size: 12pt;
          font-weight: bold;
          color: var(--paper-grey-600);
        }
        drop-down {
          text-align: left;
          /* --paper-input-container-underline: {
            display: none;
          }; */
        }
        #authContentWrapper {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        #contentWrapper {
          display:flex;
          min-height:0;
          height:100%;
        }
        paper-item, paper-icon-item {
          background-color: white;
          padding: 0 16px;
          white-space: nowrap;
        }
        paper-item.secondary:hover, paper-icon-item:hover, paper-item.secondary.hover {
          cursor: pointer;
          background-color: var(--secondary-color);
          color: var(--secondary-color-text-color);
          --paper-item-focused-before: {
            background-color: transparent;
          };
        }
        paper-item.secondary:hover iron-icon, paper-icon-item:hover iron-icon {
          color: var(--secondary-color-text-color);
        }
        .appOtherAttributesContainer {
          display: flex;
          flex-wrap: wrap;
          padding: 8px 48px;
        }
        .otherAppAttributeWrapper {
          display: flex;
          flex-direction: column;
          margin: 16px;
        }
        .otherAttributeHeader {
          font-size: 10pt;
          margin-bottom: 4px;
          /* font-weight: bolder; */
          color: var(--paper-grey-600);
          /* background-color: var(--primary-color); */
          /* color: white; */
        }
        .toggleAppRowButton:not([animate=""]) {
          transition: none;
        }
        .toggleAppRowButton[rotate=""] {
          transform: rotate(-180deg);
        }
        paper-toggle-button {
          --primary-color: var(--secondary-color);
        }
        #attacherListCollapse {
          position:relative;
        }
        .collapseRow {
          cursor:pointer;
        }
        .collapseRow:hover {
          background-color: var(--paper-grey-100);
        }
        .collapseRow iron-icon {
          margin:0 10px;
        }
        .collapseRow span {
          flex-grow:1;
          text-align:left;
        }
        .collapseContent {
          padding:0 24px;
        }
        .attacherRow {
          padding: 0 10px;
          --paper-input-container-disabled: {
            opacity:1;
            pointer-events:auto;
          }
          --paper-input-container-underline-disabled: {
              display:none;
          }
        }
        .attacherRow:hover {
          background-color: var(--paper-grey-100);
        }
        .attacherRow katapult-button {
          margin-left:10px;
        }
        .attacherRow:not(:hover) .editButton {
          opacity:0;
          pointer-events:none;
        }
        .attacherRow paper-input {
          text-align:left;
          width:420px;
        }
        .addButton {
          position:absolute;
          top:-46px;
          right:0;
        }
        .commitAttacherButton {
          padding: 5px;
          display: inline-block;
          min-width: 0;
          text-align: center;
        }
        #appTypeFilter > div[slot="dropdown-trigger"] {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          background-color: var(--paper-grey-200);
          padding: 8px 12px;
          border-radius: 80px;
        }
        .appTypeFilterRow {
          display: flex;
          align-items: center;
          height: 48px;
          padding: 16px;
          box-sizing: border-box;
        }
        .rowButton {
          background-color: var(--paper-grey-100);
        }
        .rowButton:hover {
          cursor: pointer;
        }
        .rowButton paper-cell {
          max-width: initial;
        }
        .loading-overlay {
          z-index: 1;
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;
        }
        paper-spinner-lite {
          height: 48px;
          width: 48px;
          --paper-spinner-color: var(--secondary-color);
          --paper-spinner-stroke-width: 6px;
        }
        s-circle-progress {
          margin: 0;
          flex-shrink: 0;
          height: 24px;
          width: 24px;
          --s-circle-progress-transition: stroke-dashoffset 0;
          --s-circle-progress-stroke-color:var(--paper-grey-700);
          --s-circle-progress-bg-stroke-color: var(--paper-grey-100);
        }
        .export-status-container {
          position: relative;
        }
        .export-status-container > * {
          transition: opacity 0.2s ease;
        }
        .export-status-container s-circle-progress {
          position: absolute;
          top: 0;
          left: 0;
        }
      </style>

      <!-- NO-FLOW ELEMENTS -->
      <excel-exports id="excelExports" user-group="[[userGroup]]"></excel-exports>
      <paper-toast id="toast"></paper-toast>

      <!-- DIALOGS -->

      <!-- Timeline dialog -->
      <katapult-dialog-legacy id="editTimelineDialog" style="width: 420px;" no-cancel-on-outside-click>
        <span slot="title">App Timeline Editor</span>
        <span slot="subtitle">APP_[[timelineEditMetadata.app_number]]</span>

        <div slot="body">
          <app-timeline-editor
            id="appTimelineEditor"
            job-id="[[timelineEditJobId]]"
            user-group="[[userGroup]]"
            models="[[portalConfig.timeline_models]]"
            show-action-buttons=""
          ></app-timeline-editor>
          <katapult-firebase-worker
            path="photoheight/jobs/[[timelineEditJobId]]/metadata"
            data="{{timelineEditMetadata}}"
          ></katapult-firebase-worker>

          <template is="dom-repeat" items="[[timelineEditMetadata.timeline.stages]]" as="stage">
            <div style="display: flex;">
              <paper-input
                index="[[index]]"
                style="flex: 1; margin-right: 16px;"
                label="[[stage.name]] Duration"
                type="number"
                value="[[stage.length]]"
                data-path="_timelineMetadataUpdate.metadata.timeline.stages.$(model.index).length"
                data-parse="int"
                on-input="updateProperty"
                ><span slot="suffix">Days</span></paper-input
              >
              <pmp-simple-date-input
                start-date="[[stage.start_date]]"
                index="[[index]]"
                style="flex: 1;"
                placeholder=""
                label="Start Date (MM/DD/YY)"
                value="[[formatTimelineDate(stage.start_date)]]"
                data-path="_timelineMetadataUpdate.metadata.$(model.stage.start_date)"
                data-parse="date"
                on-input="updateProperty"
              ></pmp-simple-date-input>
            </div>
          </template>
        </div>

        <div slot="buttons" style="width: 100%; display: flex; justify-content: space-between;">
          <katapult-button dialog-dismiss data-action="cancel" on-click="closeEditTimelineDialog">Cancel</katapult-button>
          <katapult-button dialog-confirm data-action="save" on-click="closeEditTimelineDialog" color="var(--secondary-color)"
            >Save</katapult-button
          >
        </div>
      </katapult-dialog-legacy>

      <!-- Admin dialog -->
      <!-- TODO (08-31-2023): This should eventually be removed, as the settings are not being used anywhere anymore -->
      <template is="dom-if" if="[[and(systemAdmin, portalConfig.show_regional_admin_tools)]]">
        <paper-dialog
          id="regionalAdminDialog"
          opened="{{regionalAdminDialogOpened}}"
          entry-animation="scale-up-animation"
          exit-animation="fade-out-animation"
          no-cancel-on-esc-key
          no-cancel-on-outside-click
        >
          <div title="" secondary-color="">App Portal Configuration</div>
          <paper-dialog-scrollable>
            <div class="content">
              <paper-item class="collapseRow" name="attacherListCollapse" on-click="toggleCollapse">
                <iron-icon icon="communication:business"></iron-icon>
                <span>Attachers on Record</span>
                <katapult-button
                  class="toggleAppRowButton"
                  icon="keyboard_arrow_down"
                  iconOnly
                  noBorder
                  noBackground
                  rotate$="[[attacherListOpen]]"
                ></katapult-button>
              </paper-item>
              <iron-collapse id="attacherListCollapse" opened="{{attacherListOpen}}">
                <div class="collapseContent">
                  <katapult-button class="addButton" icon="add" color="var(--secondary-color)" on-click="editAttacherAddOpen"
                    >Add Attacher</katapult-button
                  >
                  <template is="dom-if" if="[[showAddAttacher]]">
                    <paper-item class="attacherRow">
                      <paper-input id="newAttacher" no-label-float="" label="New Attacher Name" value="{{newAttacherName}}">
                        <katapult-button
                          icon="close"
                          slot="suffix"
                          title="Cancel"
                          iconOnly
                          noBorder
                          noBackground
                          on-click="editAttacherAddClose"
                        ></katapult-button>
                        <katapult-button
                          icon="check"
                          slot="suffix"
                          title="Save"
                          iconOnly
                          noBorder
                          noBackground
                          on-click="editAttacherSave"
                        ></katapult-button>
                      </paper-input>
                    </paper-item>
                  </template>
                  <template is="dom-repeat" items="[[toArray(attachers)]]">
                    <paper-item class="attacherRow">
                      <paper-input
                        id$="edit[[item.$key]]"
                        disabled$="[[!equal(item.$key, editingAttacher)]]"
                        no-label-float=""
                        value="[[item.name]]"
                      >
                        <template is="dom-if" if="[[!equal(item.$key, editingAttacher)]]">
                          <katapult-button
                            icon="create"
                            slot="suffix"
                            iconOnly
                            noBorder
                            noBackground
                            on-click="editAttacher"
                            class="editButton"
                          ></katapult-button>
                        </template>
                        <template is="dom-if" if="[[equal(item.$key, editingAttacher)]]">
                          <katapult-button
                            icon="close"
                            slot="suffix"
                            title="Cancel"
                            iconOnly
                            noBorder
                            noBackground
                            on-click="editAttacherCancel"
                          ></katapult-button>
                          <katapult-button
                            icon="check"
                            slot="suffix"
                            title="Save"
                            iconOnly
                            noBorder
                            noBackground
                            on-click="editAttacherSave"
                          ></katapult-button>
                        </template>
                      </paper-input>
                    </paper-item>
                  </template>
                </div>
              </iron-collapse>
            </div>
          </paper-dialog-scrollable>
          <div class="buttons">
            <katapult-button class="dismiss" dialog-dismiss>Close</katapult-button>
          </div>
        </paper-dialog>
      </template>

      <!-- Status dialogs -->
      <!-- TODO: this dialog needs to be converted to a katapult-dialog so that it can be a proper modal -->
      <paper-dialog
        id="notApprovedDialog"
        entry-animation="scale-up-animation"
        exit-animation="fade-out-animation"
        no-cancel-on-esc-key
        no-cancel-on-outside-click
      >
        <div title="" amber=""><iron-icon icon="hourglass-empty"></iron-icon>Pending Approval</div>
        <div content="">
          <p>[[pendingApprovalText]]</p>
        </div>
        <div buttons="">
          <katapult-button dialog-dismiss>Close</katapult-button>
        </div>
      </paper-dialog>
      <!-- TODO: this dialog needs to be converted to a katapult-dialog so that it can be a proper modal -->
      <paper-dialog
        id="needToRequestDialog"
        entry-animation="scale-up-animation"
        exit-animation="fade-out-animation"
        no-cancel-on-esc-key
        no-cancel-on-outside-click
      >
        <div title="" secondary-color="">No Permission Requested</div>
        <div style="text-align: center;" content="">
          <div>Your company does not have permission to submit on behalf of any companies</div>
          <template is="dom-if" if="[[or(admin, systemAdmin)]]">
            <div>You can request permission from the admin panel</div>
          </template>
          <template is="dom-if" if="[[!or(admin, systemAdmin)]]">
            <div>A company admin can request permission from the admin panel</div>
          </template>
        </div>
        <div buttons="">
          <template is="dom-if" if="[[or(admin, systemAdmin)]]">
            <a style="text-decoration: none;" href="[[adminUrl]]"
              ><katapult-button color="var(--secondary-color)">Go to Admin Panel</katapult-button></a
            >
          </template>
        </div>
      </paper-dialog>

      <!-- AUTH WRAPPER -->
      <katapult-auth
        user="{{user}}"
        user-group="{{userGroup}}"
        user-record="{{userRecord}}"
        can-read="{{userCanRead}}"
        can-write="{{userCanWrite}}"
        email="{{loginEmailBox}}"
        signed-in="{{signedIn}}"
        admin="{{admin}}"
        system-admin="{{systemAdmin}}"
        root-company="{{rootCompany}}"
        title="[[config.firebaseData.name]] Pole Applications"
      >
        <!-- Auth loding details -->
        <div slot="title-content">
          <template is="dom-if" if="[[equal(utilityCompany, 'ppl_attachments')]]">
            <p style="margin: 0;">PPL Pole Attachments Portal</p>
          </template>
          <template is="dom-if" if="[[!equal(utilityCompany, 'ppl_attachments')]]">
            <p style="margin: 0;">[[config.firebaseData.name]]</p>
            <p style="margin: 0;">Pole Applications</p>
          </template>
        </div>
        <div slot="description-content">
          <template is="dom-if" if="[[equal(utilityCompany, 'ppl_attachments')]]">
            <p>
              For information about the new interface, check out our
              <a href$="[[gettingStartedUrl]]" style="color: white; font-weight: normal; text-decoration: none;">Getting Started</a> guide
            </p>
            <p>
              If you have questions or need support, please call us at 717-430-0910 or email us at
              <a style="color: white; font-weight: normal; text-decoration: none;" href="mailto:support@katapultengineering.com"
                >support@katapultengineering.com</a
              >
            </p>
          </template>
          <template is="dom-if" if="[[!equal(utilityCompany, 'ppl_attachments')]]">
            <p>[[config.firebaseData.name]] Pole Applications allows you to see your submitted and draft applications in one place.</p>
            <p>Submitting an attachment application is now paper-free and easier than ever!</p>
            <template is="dom-if" if="{{!appNameContains(config.firebaseData.name, 'katapult')}}">
              <p>
                First time here? View our
                <a href$="[[gettingStartedUrl]]" style="color: white; font-weight: normal; text-decoration: none;">Getting Started</a> guide
              </p>
            </template>
          </template>
        </div>

        <!-- Pole app sign up form -->
        <pole-application-sign-up-form
          slot="sign-up-form"
          config="[[config]]"
          email="{{loginEmailBox}}"
          attachers="{{attachers}}"
        ></pole-application-sign-up-form>

        <!-- AUTH CONTENT -->
        <div id="authContentWrapper" slot="auth-content">
          <!-- NO-FLOW ELEMENTS -->
          <!-- Read only -->
          <katapult-firebase-worker
            path="photoheight/job_permissions/[[userGroup]]/jobs"
            data="{{jobSummary}}"
            child-events
            loading="{{loadingJobSummary}}"
            disabled="[[!signedIn]]"
          ></katapult-firebase-worker>
          <katapult-firebase-worker
            path="photoheight/company_space/[[userGroup]]/options/job_chooser"
            data="{{jobChooserOptions}}"
            disabled="[[!signedIn]]"
          ></katapult-firebase-worker>
          <katapult-firebase-worker
            path="photoheight/company_space/[[utilityCompany]]/models/attributes"
            data="{{companyAttributes}}"
            disabled="[[!signedIn]]"
            equal="[[equal]]"
          ></katapult-firebase-worker>
          <katapult-firebase-worker
            id="attacherList"
            path="photoheight/company_space/[[userGroup]]/attacher_list"
            data="{{attacherList}}"
            loading="{{attacherListLoading}}"
            disabled="[[!signedIn]]"
          ></katapult-firebase-worker>
          <katapult-firebase-worker
            path="photoheight/company_space/[[utilityCompany]]/models/attributes/new_attach_type/picklists/default"
            data="{{newAttachTypes}}"
            disabled="[[!signedIn]]"
          ></katapult-firebase-worker>
          <katapult-firebase-worker
            id="utilityCompanyUsers"
            path="users/[[utilityCompany]]"
            data="{{utilityCompanyUsers}}"
            disabled="[[!signedIn]]"
          ></katapult-firebase-worker>
          <katapult-firebase-worker path="photoheight/companies" data="{{companies}}" disabled="[[!signedIn]]"></katapult-firebase-worker>

          <iron-a11y-keys keys="esc" on-keys-pressed="hideFilters"></iron-a11y-keys>

          <!-- Toolbar -->
          <katapult-toolbar simple-help="[[equal(utilityCompany, 'ppl_attachments')]]" user-group="[[userGroup]]" hide-camera-info>
            <!-- Left Content -->
            <div slot="left" style="flex: 1; display: flex; justify-content: center;">
              <template is="dom-if" if="[[filteredAppTypes.length]]">
                <paper-menu-button
                  id="appTypeFilter"
                  horizontal-align="left"
                  vertical-align="top"
                  vertical-offset="48"
                  horizontal-offset="8"
                >
                  <div slot="dropdown-trigger">
                    <katapult-icon icon="assignment" color="var(--primary-text-color-faded)"></katapult-icon>
                    <span>[[getAppTypeSummary(filteredAppTypes.*)]]</span>
                  </div>

                  <div slot="dropdown-content">
                    <template is="dom-repeat" items="[[filteredAppTypes]]">
                      <div class="appTypeFilterRow" on-click="toggleAppTypeFilter">
                        <paper-checkbox style="pointer-events: none;" checked="[[item.$visible]]"></paper-checkbox>
                        <span>[[plural(item.display_name)]]</span>
                      </div>
                    </template>
                    <div class="appTypeFilterRow" on-click="toggleAllAppTypes" style="cursor: pointer;">
                      <iron-icon icon="select-all" style="margin:0 5px 0 -2px;"></iron-icon>
                      <span>Toggle All</span>
                    </div>
                  </div>
                </paper-menu-button>
              </template>
            </div>

            <!-- Center Content -->
            <app-filter-input
              slot="center"
              style="align-self: flex-start; z-index: 1;"
              filtered-overview-attributes="[[filteredOverviewAttributes]]"
              company-attributes="[[companyAttributes]]"
              attachers="[[attachers]]"
              portal-app-statuses="[[portalAppStatuses]]"
              filter-on-admin-strategy="[[filterOnAdminStrategy]]"
              companies="[[companies]]"
              filtering-jobs="[[filteringJobs]]"
              on-filters-updated="updateFilters"
              utility-company="[[utilityCompany]]"
            ></app-filter-input>

            <!-- Right content -->
            <div style="flex-grow: 1;" slot="right"></div>
            <template is="dom-if" if="[[showCreateButton(approvedToSubmit, portalConfig.allow_drafts_before_approval)]]">
              <paper-menu-button
                id="createMenu"
                slot="right"
                horizontal-align="left"
                vertical-align="top"
                vertical-offset="48"
                horizontal-offset="8"
              >
                <katapult-button
                  id="createButton"
                  icon="add"
                  style="margin-right: 24px;"
                  slot="dropdown-trigger"
                  color="var(--secondary-color)"
                  loader-in-button=""
                  >[[createButtonText]]</katapult-button
                >
                <div slot="dropdown-content">
                  <template is="dom-repeat" items="[[createAppTypes]]">
                    <paper-item class="secondary" on-click="createApplication">[[item.display_name]]</paper-item>
                  </template>
                </div>
              </paper-menu-button>
            </template>

            <div style="display: flex; justify-content: center; align-items: center;" slot="right">
              <paper-menu-button horizontal-align="left" vertical-align="top" vertical-offset="48" horizontal-offset="-65">
                <katapult-button id="exportButton" icon="trending_up" iconOnly noBorder slot="dropdown-trigger"></katapult-button>
                <div slot="dropdown-content">
                  <paper-icon-item disabled="[[runningExports.overview.isRunning]]" data-export-type="Overview" on-click="export">
                    <div slot="item-icon" class="export-status-container">
                      <katapult-icon icon="format_list_bulleted" invisible$="[[runningExports.overview.isRunning]]"></katapult-icon>
                      <s-circle-progress
                        value="[[runningExports.overview.progress]]"
                        max="1"
                        invisible$="[[!runningExports.overview.isRunning]]"
                      ></s-circle-progress>
                    </div>
                    <span>Overview Export</span>
                  </paper-icon-item>

                  <paper-icon-item disabled="[[runningExports.apps.isRunning]]" data-export-type="Apps" on-click="export">
                    <div slot="item-icon" class="export-status-container">
                      <katapult-icon icon="circle" invisible$="[[runningExports.apps.isRunning]]"></katapult-icon>
                      <s-circle-progress
                        value="[[runningExports.apps.progress]]"
                        max="1"
                        invisible$="[[!runningExports.apps.isRunning]]"
                      ></s-circle-progress>
                    </div>
                    <span>App Export</span>
                  </paper-icon-item>

                  <template is="dom-if" if="[[enabledFeatures.ppl_preconstruction_portal_export]]">
                    <paper-icon-item
                      disabled="[[runningExports.preconstruction.isRunning]]"
                      data-export-type="Preconstruction"
                      on-click="export"
                    >
                      <div slot="item-icon" class="export-status-container">
                        <katapult-icon icon="construction" invisible$="[[runningExports.preconstruction.isRunning]]"></katapult-icon>
                        <s-circle-progress
                          value="[[runningExports.preconstruction.progress]]"
                          max="1"
                          invisible$="[[!runningExports.preconstruction.isRunning]]"
                        ></s-circle-progress>
                      </div>
                      <span>PPL Pre-Construction Export</span>
                    </paper-icon-item>
                  </template>

                  <template is="dom-if" if="[[showCostExport(systemAdmin, config.appName)]]">
                    <paper-icon-item disabled="[[runningExports.cost.isRunning]]" data-export-type="Cost" on-click="export">
                      <div slot="item-icon" class="export-status-container">
                        <katapult-icon icon="attach_money" invisible$="[[runningExports.cost.isRunning]]"></katapult-icon>
                        <s-circle-progress
                          value="[[runningExports.cost.progress]]"
                          max="1"
                          invisible$="[[!runningExports.cost.isRunning]]"
                        ></s-circle-progress>
                      </div>
                      <span>Cost Export</span>
                    </paper-icon-item>
                  </template>

                  <paper-icon-item disabled="[[runningExports.poles.isRunning]]" data-export-type="Poles" on-click="export">
                    <div slot="item-icon" class="export-status-container">
                      <katapult-icon icon="library_books" invisible$="[[runningExports.poles.isRunning]]"></katapult-icon>
                      <s-circle-progress
                        value="[[runningExports.poles.progress]]"
                        max="1"
                        invisible$="[[!runningExports.poles.isRunning]]"
                      ></s-circle-progress>
                    </div>
                    <span>Poles Export</span>
                  </paper-icon-item>

                  <paper-icon-item disabled="[[runningExports.kmz.isRunning]]" data-export-type="KMZ" on-click="export">
                    <div slot="item-icon" class="export-status-container">
                      <katapult-icon icon="share" invisible$="[[runningExports.kmz.isRunning]]"></katapult-icon>
                      <s-circle-progress
                        value="[[runningExports.kmz.progress]]"
                        max="1"
                        invisible$="[[!runningExports.kmz.isRunning]]"
                      ></s-circle-progress>
                    </div>
                    <span>KMZ Export</span>
                  </paper-icon-item>

                  <template is="dom-if" if="{{showInvoiceExport(userGroup, config.appName)}}">
                    <paper-icon-item disabled="[[runningExports.ppl_invoice.isRunning]]" data-export-type="PPL Invoice" on-click="export">
                      <div slot="item-icon" class="export-status-container">
                        <katapult-icon icon="attach_money" invisible$="[[runningExports.ppl_invoice.isRunning]]"></katapult-icon>
                        <s-circle-progress
                          value="[[runningExports.ppl_invoice.progress]]"
                          max="1"
                          invisible$="[[!runningExports.ppl_invoice.isRunning]]"
                        ></s-circle-progress>
                      </div>
                      <span>PPL Invoice Tracking Export</span>
                    </paper-icon-item>

                    <paper-icon-item
                      disabled="[[runningExports.export_timeline.isRunning]]"
                      data-export-type="Export Timeline"
                      on-click="export"
                    >
                      <div slot="item-icon" class="export-status-container">
                        <katapult-icon icon="date_range" invisible$="[[runningExports.export_timeline.isRunning]]"></katapult-icon>
                        <s-circle-progress
                          value="[[runningExports.export_timeline.progress]]"
                          max="1"
                          invisible$="[[!runningExports.export_timeline.isRunning]]"
                        ></s-circle-progress>
                      </div>
                      <span>Timeline Export</span>
                    </paper-icon-item>
                    <paper-icon-item on-click="importTimeline"
                      ><katapult-icon icon="file_upload" slot="item-icon"></katapult-icon>Timeline Import</paper-icon-item
                    >
                    <input
                      id="importTimelineInput"
                      style="display: none;"
                      type="file"
                      accept=".csv"
                      on-change="importTimelineInputChanged"
                    />
                  </template>
                  <template is="dom-if" if="[[enabledFeatures.kpi_milestone_portal_export]]">
                    <paper-icon-item
                      disabled="[[runningExports.kpi_milestones.isRunning]]"
                      data-export-type="KPI Milestones"
                      on-click="export"
                    >
                      <div slot="item-icon" class="export-status-container">
                        <katapult-icon icon="insert_chart" invisible$="[[runningExports.kpi_milestones.isRunning]]"></katapult-icon>
                        <s-circle-progress
                          value="[[runningExports.kpi_milestones.progress]]"
                          max="1"
                          invisible$="[[!runningExports.kpi_milestones.isRunning]]"
                        ></s-circle-progress>
                      </div>
                      <span>KPI Milestones</span>
                    </paper-icon-item>
                  </template>
                  <template is="dom-if" if="[[enabledFeatures.cost_causer_splits_portal_export]]">
                    <paper-icon-item
                      disabled="[[runningExports.cost_causer_splits.isRunning]]"
                      data-export-type="Cost Causer Splits"
                      on-click="export"
                    >
                      <div slot="item-icon" class="export-status-container">
                        <katapult-icon icon="people_outline" invisible$="[[runningExports.cost_causer_splits.isRunning]]"></katapult-icon>
                        <s-circle-progress
                          value="[[runningExports.cost_causer_splits.progress]]"
                          max="1"
                          invisible$="[[!runningExports.cost_causer_splits.isRunning]]"
                        ></s-circle-progress>
                      </div>
                      <span>Cost Causer Splits</span>
                    </paper-icon-item>
                  </template>
                  <paper-icon-item disabled="[[runningExports.accrual.isRunning]]" data-export-type="Accrual" on-click="export">
                    <div slot="item-icon" class="export-status-container">
                      <katapult-icon icon="pie_chart" invisible$="[[runningExports.accrual.isRunning]]"></katapult-icon>
                      <s-circle-progress
                        value="[[runningExports.accrual.progress]]"
                        max="1"
                        invisible$="[[!runningExports.accrual.isRunning]]"
                      ></s-circle-progress>
                    </div>
                    <span>Accrual Export</span>
                  </paper-icon-item>
                </div>
              </paper-menu-button>
              <paper-tooltip animation-delay="0" for="exportButton">Export</paper-tooltip>

              <template is="dom-if" if="[[and(systemAdmin, portalConfig.show_regional_admin_tools)]]">
                <katapult-button
                  id="regionalAdminButton"
                  icon="settings"
                  iconOnly
                  noBorder
                  on-click="openRegionalAdminDialog"
                ></katapult-button>
                <paper-tooltip animation-delay="0" for="regionalAdminButton">Portal Configuration</paper-tooltip>
              </template>
            </div>
          </katapult-toolbar>

          <!-- MAIN CONTENT -->
          <div id="contentWrapper">
            <!-- Data table -->
            <paper-table>
              <div class="loading-overlay">
                <paper-spinner-lite active="[[loadingJobs]]"></paper-spinner-lite>
              </div>

              <!-- Overview header -->
              <paper-row id="overviewHeader">
                <paper-cell></paper-cell>
                <!-- Status Cell -->
                <template is="dom-repeat" items="[[filteredOverviewAttributes]]" as="overviewAttribute">
                  <template is="dom-if" if="[[overviewAttribute.top_level]]">
                    <!-- TODO: User plain flex number and make this not so gross? making style$ is better -->
                    <paper-cell style$="flex-grow: [[overviewAttribute.flex_grow]];" on-click="updateSortAttribute">
                      <span style="pointer-events: none;"
                        >[[firstValid(overviewAttribute.label, overviewAttribute.$model.label, overviewAttribute.attribute)]]</span
                      >

                      <template is="dom-if" if="[[equal(sortAttr, overviewAttribute.attribute)]]">
                        <iron-icon style="pointer-events: none;" hidden$="[[!sortReverse]]" icon="arrow-drop-down"></iron-icon>
                        <iron-icon style="pointer-events: none;" hidden$="[[sortReverse]]" icon="arrow-drop-up"></iron-icon>
                      </template>
                    </paper-cell>
                  </template>
                </template>

                <paper-cell class="button"></paper-cell>
                <!-- Toggle App Button Cell -->
                <paper-cell class="button"></paper-cell>
                <!-- Open App Button Cell -->
                <template is="dom-if" if="[[originIsDev]]">
                  <paper-cell class="button"></paper-cell>
                  <!-- Firebase Job Link Cell -->
                </template>
              </paper-row>

              <!-- Data rows -->
              <iron-list id="appList" items="[[sortedFilteredJobRefs]]" on-scroll="ironListScroll">
                <template>
                  <div class="appRow" on-dblclick="openApp" key="[[item.key]]" name="[[item.jobName]]">
                    <!-- Job row -->
                    <template is="dom-if" if="[[item.isJob]]" restamp="">
                      <paper-row>
                        <paper-cell>
                          <div class="statusIcon" style$="background-color: [[item.$statusColor]];"></div>
                        </paper-cell>

                        <template is="dom-repeat" items="[[item.$overviewAttributes]]" as="attr">
                          <template is="dom-if" if="[[attr.top_level]]">
                            <!-- TODO: on_click is not included in new config object -->
                            <paper-cell
                              style$="[[getAttributeStyle(item.$job.metadata, attr)]]"
                              clickable$="[[attr.on_click]]"
                              app-id="[[item.key]]"
                              attr="[[attr]]"
                              on-click="attributeClickHandler"
                              on-dblclick="attributeDoubleClickHandler"
                            >
                              <!-- TODO (10-06-2022): These highlight-text guys mess with font-colors, not a problem with normal text, but color on color looks weird -->
                              <template is="dom-if" if="[[equal(attr.display_as, 'date')]]" restamp="">
                                <highlight-text title$="[[formatDate(attr.$value)]]" filters="[[getHighlightFilters(searchString, attr)]]"
                                  ><span>[[formatDate(attr.$value)]]</span></highlight-text
                                >
                              </template>

                              <template is="dom-if" if="[[equal(attr.display_as, 'company')]]" restamp="">
                                <highlight-text
                                  title$="[[getCompanyName(attr.$value, attr.attribute)]]"
                                  filters="[[getHighlightFilters(searchString, attr)]]"
                                  ><span>[[getCompanyName(attr.$value, attr.attribute)]]</span></highlight-text
                                >
                              </template>

                              <template is="dom-if" if="[[equal(attr.display_as, 'user')]]" restamp>
                                <template is="dom-if" if="[[attr.$value]]" restamp>
                                  <user-chip uid="[[attr.$value]]"></user-chip>
                                </template>
                              </template>

                              <template is="dom-if" if="[[equal(attr.display_as, 'boolean')]]" restamp="">
                                <paper-checkbox checked="[[attr.$value]]" disabled=""></paper-checkbox>
                              </template>

                              <template is="dom-if" if="[[equal(attr.display_as, 'computed')]]" restamp="">
                                <highlight-text
                                  title$="[[calcComputed(attr, item.$job.metadata)]]"
                                  filters="[[getHighlightFilters(searchString, attr)]]"
                                  ><span>[[calcComputed(attr, item.$job.metadata)]]</span></highlight-text
                                >
                              </template>

                              <template is="dom-if" if="[[equal(attr.display_as, 'job_attribute')]]" restamp>
                                <highlight-text
                                  title$="[[getSub(jobSummary, item.key, attr.attribute)]]"
                                  filters="[[getHighlightFilters(searchString, attr)]]"
                                  ><span>[[getSub(jobSummary, item.key, attr.attribute)]]</span></highlight-text
                                >
                              </template>

                              <template is="dom-if" if="[[!attr.display_as]]" restamp="">
                                <highlight-text title$="[[attr.$value]]" filters="[[getHighlightFilters(searchString, attr)]]"
                                  ><span>[[attr.$value]]</span></highlight-text
                                >
                              </template>
                            </paper-cell>
                          </template>
                        </template>

                        <paper-cell class="button">
                          <katapult-button
                            app-id="[[item.key]]"
                            class="toggleAppRowButton"
                            icon="keyboard_arrow_down"
                            iconOnly
                            noBorder
                            noBackground
                            rotate$="[[item.$display.rowExpanded]]"
                            animate$="[[item.$display.animateExpand]]"
                            on-click="toggleAppRow"
                            on-dblclick="stopPropagation"
                          ></katapult-button>
                        </paper-cell>

                        <paper-cell class="button">
                          <a href$="[[getAppUrl(item.key)]]" target="_blank"
                            ><katapult-button icon="open_in_new" iconOnly noBorder noBackground></katapult-button
                          ></a>
                        </paper-cell>

                        <template is="dom-if" if="[[originIsDev]]">
                          <paper-cell class="button">
                            <a
                              href$="https://console.firebase.google.com/u/1/project/[[config.appName]]/database/[[config.appName]]/data/photoheight/jobs/[[item.key]]"
                              ><katapult-button icon="link" iconOnly noBorder noBackground></katapult-button
                            ></a>
                          </paper-cell>
                        </template>
                      </paper-row>

                      <iron-collapse
                        app-id="[[item.key]]"
                        opened="[[item.$display.rowExpanded]]"
                        no-animation="[[!item.$display.animateExpand]]"
                        on-transitioning-changed="appExpandTransitionChanged"
                      >
                        <div class="appOtherAttributesContainer">
                          <template
                            is="dom-if"
                            if="[[item.$display.showCollapseContent]]"
                            restamp=""
                            on-dom-change="collapseContentChanged"
                          >
                            <template is="dom-repeat" items="[[item.$overviewAttributes]]" as="attr">
                              <template is="dom-if" if="[[!attr.top_level]]">
                                <template is="dom-if" if="[[attr.$value]]">
                                  <div class="otherAppAttributeWrapper">
                                    <span class="otherAttributeHeader">[[getAttributeName(attr)]]:</span>
                                    <template is="dom-if" if="[[equal(attr.display_as, 'date')]]" restamp="">
                                      <highlight-text filters="[[getHighlightFilters(searchString, attr)]]"
                                        ><span>[[formatDate(attr.$value)]]</span></highlight-text
                                      >
                                    </template>

                                    <template is="dom-if" if="[[equal(attr.display_as, 'company')]]" restamp="">
                                      <highlight-text filters="[[getHighlightFilters(searchString, attr)]]"
                                        ><span>[[getCompanyName(attr.$value, attr.attribute)]]</span></highlight-text
                                      >
                                    </template>

                                    <template is="dom-if" if="[[equal(attr.display_as, 'boolean')]]" restamp="">
                                      <paper-checkbox checked="[[attr.$value]]" disabled=""></paper-checkbox>
                                    </template>

                                    <template is="dom-if" if="[[!attr.display_as]]" restamp="">
                                      <highlight-text filters="[[getHighlightFilters(searchString, attr)]]"
                                        ><span>[[attr.$value]]</span></highlight-text
                                      >
                                    </template>
                                  </div>
                                </template>
                              </template>
                            </template>
                          </template>
                        </div>
                      </iron-collapse>
                    </template>

                    <!-- Non-job row -->
                    <template is="dom-if" if="[[!item.isJob]]" restamp>
                      <template is="dom-if" if="[[item.show_drafts]]" restamp>
                        <div class="rowButton" on-click="showAllDrafts">
                          <paper-row><paper-cell>Show [[numDrafts]] Drafts</paper-cell></paper-row>
                        </div>
                      </template>
                    </template>
                  </div>
                </template>
              </iron-list>
              <template is="dom-if" if="[[numHiddenJobs]]" restamp>
                <div class="rowButton" on-click="showAllJobs">
                  <paper-row><paper-cell ripple>Show [[numHiddenJobs]] Hidden Jobs</paper-cell></paper-row>
                </div>
              </template>
            </paper-table>
          </div>
        </div>
      </katapult-auth>
    `;
  }

  static get is() {
    return 'pole-application-overview';
  }

  static get importMeta() {
    return import.meta;
  }

  static get properties() {
    return {
      adminUrl: {
        type: String,
        value: () =>
          window.location.origin +
          window.location.pathname
            .split('/')
            .map((x) => (x == 'pole-application-overview' ? 'admin' : x))
            .join('/')
      },
      appOptions: {
        type: Object,
        value: () => ({})
      },
      attacherList: {
        type: Object
      },
      approvedToSubmit: {
        type: Boolean,
        value: false
      },
      createButtonText: {
        type: String,
        value: 'Create'
      },
      filterAttributeValues: {
        type: Object,
        value: () => ({})
      },
      filteredOverviewAttributes: {
        type: Array,
        computed:
          'calcFilteredOverviewAttributes(portalConfig.display_options.overview_attributes, userGroup, utilityCompany, portalConfig.user_options.review_contractors, portalConfig.user_options.engineering_contractors, portalConfig.user_options.data_collection_contractors, companyAttributes)'
      },
      gettingStartedUrl: {
        type: String,
        value: () =>
          window.location.origin +
          window.location.pathname
            .split('/')
            .map((x) => (x == 'pole-application-overview' ? 'pole-application-getting-started' : x))
            .join('/')
      },
      loadingJobs: {
        type: Boolean,
        value: true
      },
      numJobsToShow: {
        type: Number,
        value: 100
      },
      portalAppStatuses: {
        type: Array,
        computed: 'calcPortalAppStatuses(portalConfig.app_types)'
      },
      rootCompany: {
        type: String,
        value: null
      },
      showCompletedApps: {
        type: Boolean,
        value: false
      },
      showDrafts: {
        type: Boolean,
        value: false
      },
      sortAttr: {
        type: String,
        value: 'app_number'
      },
      sortReverse: {
        type: Boolean,
        value: true
      },
      sortedFilteredJobRefs: {
        type: Array,
        value: () => []
      },
      allSortedFilteredJobRefs: {
        type: Array,
        value: () => []
      },
      createAppTypes: {
        type: Array,
        value: () => []
      },
      filteredAppTypes: {
        type: Array,
        value: () => []
      },
      filteringJobs: {
        type: Boolean,
        value: false
      },
      runningExports: {
        type: Object,
        value: () => ({})
      },
      enabledFeatures: {
        type: Object,
        value: () => ({})
      },
      filterOnAdminStrategy: {
        type: Boolean,
        computed: 'computeFilterOnAdminStrategy(userGroup, utilityCompany, portalConfig.user_options.admin_strategy)'
      },
      signedIn: {
        type: Boolean,
        value: false
      }
    };
  }

  static get observers() {
    return [
      'getAppTypes(portalConfig.app_types)',
      'calcCreateAppTypes(portalConfig.app_types, userGroup, utilityCompany, portalConfig.user_options.review_contractors, attacherList.*)',
      'filteredAppTypesChanged(filteredAppTypes.*)',
      'calcSortedFilteredJobRefs(jobSummary.*, filteredOverviewAttributes, jobChooserOptions, searchString, sortAttr, sortReverse, filterAttributeValues.*, showCompletedApps, showDrafts, filteredAppTypes.*, showArchived, numJobsToShow)',
      'logOverviewLoaded(user.uid)',
      'updateApprovedToSubmit(userGroup, utilityCompany, attacherList.*, attacherListLoading, userCanRead)',
      'setDefaultSortAttribute(portalConfig.default_sort_attribute)',
      'loadPortalConfig(utilityCompany)'
    ];
  }

  constructor() {
    super();
    this.originIsDev = checkIsDevelopmentOrigin();
    // Template helpers
    this.plural = pluralize;
  }

  connectedCallback() {
    super.connectedCallback();

    this.kpiMilestonePortalExportFlagListener = addFlagListener('kpi_milestone_portal_export', (enabled) => {
      this.set('enabledFeatures.kpi_milestone_portal_export', enabled);
    });
    this.costCauserSplitsPortalExportFlagListener = addFlagListener('cost_causer_splits_portal_export', (enabled) => {
      this.set('enabledFeatures.cost_causer_splits_portal_export', enabled);
    });
    this.costCauserSplitsPortalExportFlagListener = addFlagListener('ppl_preconstruction_portal_export', (enabled) => {
      this.set('enabledFeatures.ppl_preconstruction_portal_export', enabled);
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    clearInterval(this.resizeInterval);

    removeFlagListener('kpi_milestone_portal_export', this.kpiMilestonePortalExportFlagListener);
    removeFlagListener('cost_causer_splits_portal_export', this.costCauserSplitsPortalExportFlagListener);
    removeFlagListener('ppl_preconstruction_portal_export', this.pplPreconstructionPortalExportFlagListener);
  }

  ready() {
    zip.workerScriptsPath = '../_resources/js/open-source/zipjs/';

    super.ready();

    this.appConfiguration = new AppConfiguration(this, {
      dataLoaded: (config) => {
        this.utilityCompany = config.firebaseData.utilityCompany;
      }
    });
  }

  _getAppsFilterWorker() {
    const appFilterWorker = new Worker(new URL('./worker-functions/AppsFilterWorker.js', import.meta.url), { type: 'module' });
    return { sortAndFilterJobRefs: Comlink.wrap(appFilterWorker), terminate: () => appFilterWorker.terminate() };
  }

  ironListScroll(e) {
    const scrollTop = e.currentTarget.scrollTop;
    if (scrollTop !== this.ironListScrollTop) {
      if (scrollTop > 0 || Math.abs(scrollTop - this.ironListScrollTop) < 50) this.ironListScrollTop = scrollTop;
      else e.currentTarget.scrollTop = this.ironListScrollTop;
    }
  }

  appNameContains(appName, searchString) {
    return (appName || '').toLowerCase().indexOf(searchString.toLowerCase()) != -1;
  }

  getAppTypes() {
    // Get an array of app types from the master config object
    this.filteredAppTypes = ToArray(this.portalConfig?.app_types).map((x) => ({ ...x, $visible: x.$key }));
  }

  calcCreateAppTypes() {
    // Get an array of app types from the master config object
    const appTypesList = Object.entries(this.portalConfig?.app_types ?? {}).map(([$key, x]) => ({ ...x, $key }));
    // Bail if there are no app types
    if (!appTypesList?.length) return;

    // Determine if the user group is a portal admin
    const isReviewContractor = Boolean(this.portalConfig?.user_options?.review_contractors?.[this.userGroup]);
    const isPortalAdmin = this.userGroup == this.utilityCompany || isReviewContractor;
    // Filter the app types to those visible to the user
    this.createAppTypes = appTypesList.filter((x) => !x.utility_create_only || isPortalAdmin);
  }

  attributeClickHandler(e) {
    let attr = e.currentTarget.attr;
    let onClick = attr.on_click;
    let jobId = e.currentTarget.appId;
    if (jobId) {
      let metadata = this.get(`jobSummary.${jobId}.metadata`);
      if (onClick === 'editAppTimeline') {
        this.timelineEditJobId = jobId;
        this._timelineMetadataUpdate = null;
        this.$.editTimelineDialog.open();
      } else if (onClick === 'toggle') {
        let update = {};
        update[`metadata/${attr.attribute}`] = !Path.get(metadata, attr.attribute);
        FirebaseWorker.ref(`photoheight/jobs/${jobId}`).update(update);
        UpdateJobPermissions(jobId, this.userGroup, update, {
          fetchSharedCompanies: true
        });
      }
    }
  }

  attributeDoubleClickHandler(e) {
    if (Path.get(e, 'model.attr.on_click')) {
      preventDefault(e);
    }
  }

  appExpandTransitionChanged(e) {
    const jobRefDisplayPath = `sortedFilteredJobRefs.${e.model.index}.$display`;
    const jobRefDisplay = this.get(jobRefDisplayPath) ?? {};
    // If transitioning is stopped (not starting) and rowExpanded has been initialized.
    if (!e.detail.value && jobRefDisplay.rowExpanded != null) {
      // When not transitioning (done), turn animation off.
      if (jobRefDisplay.animateExpand) this.set(`${jobRefDisplayPath}.animateExpand`, false);
      // When transitioning stops, if the collapse is closed, hide the content.
      if (!jobRefDisplay.rowExpanded) this.set(`${jobRefDisplayPath}.showCollapseContent`, false);
    }
  }

  getHighlightFilters(searchString, overviewAttribute) {
    // Split the search string by spaces
    let searchTerms = searchString?.split(' ') ?? [];
    let filterValue = this.filterAttributeValues[overviewAttribute.attribute];

    if (!overviewAttribute) return searchTerms;

    // Get additional search terms by variable type
    let attributeType = overviewAttribute.type;
    if (attributeType == 'date') {
      let dateFilterValues = Object.values(filterValue ?? {});
      searchTerms.push(...dateFilterValues.map((x) => this.formatDate(x)));
    } else if (attributeType == 'company') searchTerms.push(this.getCompanyName(filterValue, overviewAttribute.attribute));
    else searchTerms.push(filterValue);

    // Return the search terms
    return searchTerms.filter((x) => !!x);
  }

  closeEditTimelineDialog(e) {
    // Determine close action
    let action = e.currentTarget.dataset.action;

    // If the action is save, save the data to firebase
    if (action == 'save') {
      // Make the update object flat
      let update = Path.flattenObject(this._timelineMetadataUpdate, '/');

      // Commit the update in firebase
      if (Object.keys(update).length) {
        FirebaseWorker.ref(`photoheight/jobs/${this.timelineEditJobId}`).update(update);
        UpdateJobPermissions(this.timelineEditJobId, this.userGroup, update, { fetchSharedCompanies: true });
      }
    }

    // Clear the change buffer
    this._timelineMetadataUpdate = null;
    // Clear the timeline editor's selected model key
    this.$.appTimelineEditor.selectedModelKey = null;
  }

  calcComputed(overviewAttribute, metadata) {
    switch (overviewAttribute.attribute) {
      case 'timeline_progress': {
        const timelineProgress = getTimelineProgress(metadata);
        return timelineProgress != null ? `Day ${timelineProgress.daysElapsed} of ${timelineProgress.totalDays}` : '';
      }
      case 'days_left_in_stage': {
        const activeStageProgress = getActiveTimelineStageProgress(metadata);
        return activeStageProgress != null ? activeStageProgress.daysRemaining : '';
      }
    }
  }

  calcFilteredOverviewAttributes(
    overviewAttributes,
    userGroup,
    utilityCompany,
    reviewContractors,
    engineeringContractors,
    dataCollectionContractors
  ) {
    // Determine the role of this user.
    const isUtility = userGroup == utilityCompany;
    const isReviewContractor = Boolean(reviewContractors?.[userGroup]);
    const isEngineeringContractor = Boolean(engineeringContractors?.[userGroup]);
    const isDataCollectionContractor = Boolean(dataCollectionContractors?.[userGroup]);

    // Sort and filter the overview attributes list
    const overviewAttributesList = Object.entries(overviewAttributes ?? {}).map(([$key, attribute]) => ({ ...attribute, $key }));
    const sortedOverviewAttributes = overviewAttributesList.sort((a, b) => a.order - b.order);
    const filteredOverviewAttributes = sortedOverviewAttributes.filter((x) => {
      if (!x.limit_visibility) return true;
      if (x.visibility == null) return false;
      return (
        (x.visibility.utility_company && isUtility) ||
        (x.visibility.review_contractors && isReviewContractor) ||
        (x.visibility.engineering_contractors && isEngineeringContractor) ||
        (x.visibility.data_collection_contractors && isDataCollectionContractor)
      );
    });

    // Return the list with models
    return filteredOverviewAttributes.map((x) => ({ ...x, $model: this.companyAttributes?.[x.attribute] }));
  }

  calcPortalAppStatuses() {
    const appTypes = ToArray(this.portalConfig.app_types);
    const portalAppStatuses = [];

    // For each app type, list the statuses (making note of their source app type and ordering)
    for (const appType of appTypes) {
      const appTypeStatuses = Object.values(appType.statuses ?? {}).sort((a, b) => a.status?.localeCompare?.(b.status));
      appTypeStatuses.forEach((x, i) =>
        portalAppStatuses.push({
          status: x.name,
          appType: appType.display_name,
          order: portalAppStatuses.length
        })
      );
    }
    return portalAppStatuses;
  }

  calcSortedFilteredJobRefs() {
    // If we are not signed in or filtered overview attributes is null, ditch
    if (!this.signedIn || !this.filteredOverviewAttributes?.length) return;

    // Terminate the worker
    if (this.appsFilterWorker) this.appsFilterWorker.terminate();
    // Start loading animation
    this.filteringJobs = true;

    // Otherwise, debounce
    this.calcSortedFilteredJobRefsDebounce = Debouncer.debounce(this.calcSortedFilteredJobRefsDebounce, timeOut.after(100), async () => {
      // Get a new worker and run it!
      this.appsFilterWorker = this._getAppsFilterWorker();

      // Get data objects for sort, filter, and expand
      const filterData = {
        searchString: this.searchString,
        filterAttributeValues: this.filterAttributeValues,
        filteredAppTypes: this.filteredAppTypes,
        showArchived: this.showArchived,
        showCompletedApps: this.showCompletedApps,
        showDrafts: this.showDrafts,
        numJobsToShow: this.numJobsToShow
      };
      const sortData = {
        sortAttr: this.sortAttr,
        sortReverse: this.sortReverse
      };
      const configData = {
        filteredOverviewAttributes: this.filteredOverviewAttributes,
        limitJobChooser: this.jobChooserOptions?.limit_job_chooser ?? {},
        portalUserOptions: this.portalConfig?.user_options ?? {},
        utilityCompany: this.utilityCompany,
        userGroup: this.userGroup,
        systemAdmin: this.systemAdmin,
        userIsAdmin: katapultAuth.admin,
        userUid: katapultAuth.user.uid
      };

      // Run the sort, filter, expand operation (in a worker)
      const result = await this.appsFilterWorker.sortAndFilterJobRefs(
        this.jobSummary,
        this.sortedFilteredJobRefs,
        configData,
        filterData,
        sortData
      );

      if (!this.loadingJobSummary) this.loadingJobs = false;

      // Set the resultant data on the element
      this.sortedFilteredJobRefs = result.sortedFilteredJobRefs;
      this.allSortedFilteredJobRefs = result.allSortedFilteredJobRefs;
      this.numDrafts = result.numDrafts;
      this.numHiddenJobs = result.numHiddenJobs;

      // Update row exapnd data for each entry
      this.sortedFilteredJobRefs.forEach((x, i) => this.updateAppRowExpanded(i));
      // Stop loading animation
      this.filteringJobs = false;
    });
  }

  collapseContentChanged(e) {
    // If content is being displayed and rowExpanded has been initialized, expand the row.
    if (e.currentTarget.if && this.sortedFilteredJobRefs?.[e.model.index]?.$display.rowExpanded != null) {
      setTimeout(() => {
        this.set(`sortedFilteredJobRefs.${e.model.index}.$display.animateExpand`, true);
        this.set(`sortedFilteredJobRefs.${e.model.index}.$display.rowExpanded`, true);
        // Notify start iron list update loop.
        NotifyResizable('poleAppOverview', this.$.appList, 400);
      });
    }
  }

  async createApplication(e) {
    this.$$('#createMenu').close();
    this.$$('#createButton').loading = true;
    this.createButtonText = 'Creating';

    // Get the app type.
    const appType = e.model.item.type;

    // Create the draft.
    const { jobId } = await CreateDraft({
      userGroup: this.userGroup,
      utilityCompany: this.utilityCompany,
      appType: appType,
      userRecord: katapultAuth.userRecord
    });

    // Get a url to the new app.
    const url = this.getAppUrl(jobId);

    // Open our new app!
    this.createButtonText = 'Opening';
    window.location.href = url;
  }

  getAppTypeSummary() {
    const visibleAppTypes = this.filteredAppTypes?.filter((x) => x.$visible) ?? [];
    const count = visibleAppTypes.length;
    return count > 0 ? pluralize(visibleAppTypes[0].display_name) + (count > 1 ? ` + ${count - 1}` : '') : 'None';
  }

  toggleAllAppTypes() {
    const someAppTypesVisible = this.filteredAppTypes.some((x) => x.$visible);
    this.filteredAppTypes.forEach((x, i) => this.set(`filteredAppTypes.${i}.$visible`, !someAppTypesVisible));
  }

  toggleAppTypeFilter(e) {
    const oldValue = e.model.item.$visible;
    this.set(`filteredAppTypes.${e.model.index}.$visible`, !oldValue);
  }

  // TODO (2024-01-09): The following functions will someday be replaced by a better attacher manager
  // ----------------------------------------------------------------------------------
  toggleCollapse(e) {
    this.shadowRoot.querySelector('#' + e.currentTarget.getAttribute('name')).toggle();
    var count = 0;
    this.resizeInterval = setInterval(() => {
      count += 16;
      this.shadowRoot.querySelector('#regionalAdminDialog').notifyResize();
      if (count >= 400) clearInterval(this.resizeInterval);
    }, 16);
  }

  editAttacher(e) {
    if (this.editingAttacher != null) {
      this.shadowRoot.querySelector('#edit' + this.editingAttacher).value = this.get('attachers.' + this.editingAttacher + '.name');
    }
    this.editingAttacher = e.model.item.$key;
    e.currentTarget.parentNode.focus();
  }

  editAttacherAddClose() {
    this.showAddAttacher = false;
  }

  editAttacherAddOpen() {
    this.showAddAttacher = true;
    setTimeout(() => {
      this.shadowRoot.querySelector('#newAttacher').focus();
    });
  }

  editAttacherCancel(e) {
    e.currentTarget.parentNode.value = this.get('attachers.' + e.model.item.$key + '.name');
    this.editingAttacher = null;
  }

  editAttacherSave(e) {
    var name = e.currentTarget.parentNode.value;
    var id = this.editingAttacher;
    var update = {};
    if (e.currentTarget.parentNode.id == 'newAttacher') {
      // Get a unique key
      id = name
        .toLowerCase()
        .replace(/[\.,()+:\-\[\]\$\#\/]+/g, '')
        .replace(/[ _]+/g, '_');
      let largestNumber = 0;
      let foundMatch = false;
      for (var key in this.attachers) {
        if (key.indexOf(id) == 0) {
          foundMatch = true;
          let match = key.match(/\d+$/g);
          if (match != null) {
            match = parseInt(match[0]);
            if (match > largestNumber) {
              largestNumber = match;
            }
          }
        }
      }
      if (foundMatch) {
        id += largestNumber + 1;
      }
    } else if (this.companies[id]) {
      update['photoheight/companies/' + id + '/name'] = name;
      update['photoheight/company_space/' + id + '/name'] = name;
    }
    update['utility_info/' + this.utilityCompany + '/attachers/' + id + '/name'] = name;
    FirebaseWorker.ref()
      .update(update)
      .then(() => {
        this.$.toast.show('Company Updated');
      })
      .catch((err) => {
        this.$.toast.show(err.message);
      });
    this.editingAttacher = null;
    this.showAddAttacher = false;
    this.newAttacherName = '';
  }
  // ----------------------------------------------------------------------------------

  async export(e) {
    // Get the export type from the element's dataset
    const exportType = e.currentTarget.dataset.exportType;
    const exportKey = exportType.replaceAll(' ', '_').toLowerCase();

    // Base data config for appExport
    let dataConfig = {
      jobs: this.allSortedFilteredJobRefs.map((x) => x.key).filter((x) => x),
      jobSummary: this.jobSummary,
      companyAttributes: this.companyAttributes,
      companies: this.companies,
      attachers: this.attachers,
      overviewAttributes: this.filteredOverviewAttributes,
      poleAttributes: Object.values(this.portalConfig.app_types).map((x) => x.pole_attributes),
      newAttachTypes: this.newAttachTypes,
      color: this.get('config.firebaseData.palette.secondaryColor'),
      isUtility: this.userGroup === this.utilityCompany,
      userGroup: this.userGroup,
      fileName: `${exportType} Export`
    };

    if (exportKey == 'kpi_milestones' || exportKey == 'cost_causer_splits') {
      dataConfig.skipLoadingJobData = true;
      let jobNames = this.allSortedFilteredJobRefs
        .map((x) => x.jobName)
        .filter((x) => x)
        .join(', ');
      let canceled = false;
      const dialog = KatapultDialog.open({
        dialog: { title: 'Apps To Export', modal: true, maxWidth: 600 },
        template: () => litHtml`
          <div>The following applications will be exported with the following name. You may edit the list before proceeding.</div>
          <paper-input id="exportName" value="${exportType} Export ${DateTime.now().toISODate()}"></paper-input>
          <paper-textarea value="${jobNames}" label="" no-label-float></paper-textarea>
          <katapult-button id="cancel" slot="buttons" dialog-dismiss>Cancel</katapult-button>
          <katapult-button id="confirm" slot="buttons" color="var(--secondary-color)">Export</katapult-button>
        `
      });
      await dialog.openStart;
      dialog.querySelector('#cancel').addEventListener('click', () => (canceled = true));
      dialog.querySelector('#confirm').addEventListener('click', () => {
        jobNames = dialog.querySelector('paper-textarea').value.split(',');
        const jobNameLookup = {};
        for (const jobId in this.jobSummary) {
          jobNameLookup[this.jobSummary[jobId]?.name] = jobId;
        }
        dataConfig.jobs = jobNames.map((name) => jobNameLookup[name.trim()]).filter((x) => x);
        dataConfig.fileName = dialog.querySelector('#exportName').value ?? `${exportType} Export`;
        dialog.close();
      });
      await dialog.closeStart;
      if (canceled) return;
      await import('../katapult-maps-desktop/exports/excel-exports.js');
    }

    this.set(`runningExports.${exportKey}`, { isRunning: true, progress: 0 });
    const updateProgress = (newVal) => this.set(`runningExports.${exportKey}.progress`, newVal);

    // Get the data for the export
    let data = await AppExport.export(
      dataConfig,
      { firebase: firebase, formatDate: FormatDate, updateProgress, excelExports: this.$.excelExports },
      { type: exportType }
    );

    // If the export is a KMZ export
    if (exportType === 'KMZ') {
      zip.createWriter(new zip.BlobWriter(), function (writer) {
        writer.add('pole_export.kml', new zip.TextReader(data), () => {
          writer.close((blob) => Download(blob, `${exportType} Export.kmz`));
        });
      });
    } else if (typeof data == 'object' && data.fileURL) {
      window.open(data.fileURL);
    } else if (typeof data == 'object' && data.error) {
      this.$.toast.show(data.error);
    }

    // Otherwise, download the data as-is
    else Download(data, `${dataConfig.fileName}.csv`, 'text/csv');

    this.set(`runningExports.${exportKey}`, { isRunning: false, progress: 0 });
  }

  importTimeline() {
    this.shadowRoot.querySelector('#importTimelineInput').click();
  }

  filteredAppTypesChanged() {
    // If filtered app types is empty, short-circuit
    if (this.filteredAppTypes.length == 0) return;

    // If local storage for visible app types is initialized
    if (this.visibleAppTypesStorageIsInit) {
      // Update the local storage
      let visibleAppTypes = this.filteredAppTypes.filter((x) => x.$visible).map((x) => x.$key);
      try {
        localStorage.setItem('visibleAppTypes', JSON.stringify(visibleAppTypes));
      } catch (e) {
        console.error(e);
      }
    }

    // Otherwise, try to get visible app types from local storage
    else {
      let visibleAppTypes = [];
      try {
        visibleAppTypes = JSON.parse(localStorage.getItem('visibleAppTypes')) || [];
      } catch (e) {
        console.error(e);
      }

      // If we didn't get any visible app types from local storage, default to all filtered app types
      if (!visibleAppTypes.length) visibleAppTypes = this.filteredAppTypes.map((x) => x.$key);

      this.visibleAppTypesStorageIsInit = true;

      // Loop over each visible filtered app type and check whether it should be visible or not
      this.filteredAppTypes = this.filteredAppTypes.map((x) => ({ ...x, $visible: visibleAppTypes.includes(x.$key) }));
    }

    // If the window hash includes a create prompt
    if (window.location.hash.includes('createPrompt=')) {
      let hashSplit = window.location.hash.slice(1).split('/');
      let type = hashSplit
        .filter((x) => x.includes('createPrompt='))
        .pop()
        .split('=')[1];
      this.shadowRoot.querySelector('#createMenu').open();
      setTimeout(() => {
        let item = this.shadowRoot.querySelector(`[type="${type}"]`);
        if (item) {
          item.classList.add('hover');
          setTimeout(() => {
            item.classList.remove('hover');
          }, 1000);
        }
        window.location.hash = hashSplit.filter((x) => !x.includes('createPrompt=')).join('/');
      }, 1000);
    }
  }

  getAppUrl(appId) {
    return `../pole-application/#${appId}`;
  }

  getAttributeName(item) {
    if (item) return item.label || this.get(`companyAttributes.${item.attribute}.label`) || CamelCase(item.attribute);
  }

  getAttributeStyle(metadata, overviewAttribute) {
    if (!metadata) return;
    const styles = [];

    const styleConfigs = {
      error: ['background-color: var(--paper-red-100);', 'color: var(--paper-red-900);'],
      warning: ['background-color: var(--paper-amber-100);', 'color: var(--paper-amber-900);']
    };

    switch (overviewAttribute.attribute) {
      case 'timeline_progress': {
        const { daysRemaining } = getTimelineProgress(metadata) ?? {};
        if (daysRemaining != null && daysRemaining < 0) styles.push(...styleConfigs.error);
        else if (daysRemaining != null && daysRemaining < 7) styles.push(...styleConfigs.warning);
        break;
      }
      case 'days_left_in_stage': {
        const { daysRemaining } = getActiveTimelineStageProgress(metadata) ?? {};
        if (daysRemaining != null && daysRemaining < 0) styles.push(...styleConfigs.error);
        else if (daysRemaining != null && daysRemaining < 7) styles.push(...styleConfigs.warning);
      }
    }

    // May not be used anymore...
    if (overviewAttribute.flex_grow) styles.push(`flex-grow: ${overviewAttribute.flex_grow};`);

    return styles.join(' ');
  }

  getCompanyName(key, attr) {
    if (attr == 'attachment_owner') return this.get(`attachers.${key}.name`);
    else return this.get(`companies.${key}.name`);
  }

  formatDate(time) {
    return time ? FormatDate(parseInt(time)) : '';
  }

  formatTimelineDate(stageStartDate) {
    return stageStartDate ? this.formatDate(this.timelineEditMetadata[stageStartDate]) : '';
  }

  hideFilters() {
    const element = this.shadowRoot?.querySelector('app-filter-input');
    if (element) element.showAdvancedFilters = false;
  }

  updateSortAttribute(e) {
    // If the sort attribute is equal to the clicked header
    if (this.sortAttr == e.model.overviewAttribute.attribute) {
      // If we're already sorting in revers (and the sort attribute is not already app number)
      // FEAT (07-01-2022): Set this default in config?
      if (this.sortReverse && this.sortAttr != 'app_number') this.sortAttr = 'app_number';
      // Otherwise, reverse the sort
      else this.sortReverse = !this.sortReverse;
    }
    // Otherwise, set sort reverse to false and update the sort attribute
    else {
      this.sortReverse = false;
      this.sortAttr = e.model.overviewAttribute.attribute;
    }
  }

  async importTimelineInputChanged(e) {
    // Calc number of milliseconds per day.
    let millisPerDay = 1000 * 60 * 60 * 24;
    // Store a reference to the file.
    let file = e.target.files[0];
    // Clear the file input.
    e.target.value = null;
    // Lazyload papaparse.
    let Papa = (await import('papaparse')).Papa;
    // Parse the csv.
    Papa.parse(file, {
      header: true,
      transformHeader: (str) => SnakeCase(str)?.toLowerCase(),
      complete: async (res) => {
        let numValid = 0;
        let numInvalid = 0;
        let update = {};
        for (let job of res.data) {
          if (job.job_id) {
            // Convert the due date to a date object.
            let dueDate = new Date(job.set_due_date_for_survey_upload_required);
            dueDate.setHours(0, 0, 0, 0);
            // Check that the new due date is valid (it won't be if a due date wasn't supplied).
            if (!isNaN(dueDate.getTime())) {
              let startDate = new Date(job.survey_start_date);
              startDate.setHours(0, 0, 0, 0);
              let duration = Math.floor((dueDate.getTime() - startDate.getTime()) / millisPerDay);
              if (!isNaN(startDate.getTime()) && duration != null) {
                numValid++;
                // Update the survey length on the job's timeline.
                update[`photoheight/jobs/${job.job_id}/metadata/timeline/stages/0/length`] = duration;
                // Update the survey length in job_permissions as well.
                await UpdateJobPermissions(
                  job.job_id,
                  this.userGroup,
                  { 'metadata/timeline/stages/0/length': duration },
                  { fetchSharedCompanies: true, update }
                );
              } else {
                numInvalid++;
              }
            } else {
              numInvalid++;
            }
          }
        }
        await FirebaseWorker.ref().update(update);
        this.$.toast.show({
          text: `${numInvalid} jobs skipped due to missing or invalid timeline or due date. ${numValid} jobs updated.`,
          duration: 5000
        });
      }
    });
  }

  loadPortalConfig(utilityCompany) {
    // Ditch if utility company is undefined
    if (utilityCompany == null) return;

    // If the portal config ref exists, turn off its listener
    if (!!this.portalConfigRef) this.portalConfigRef.off();
    // Otherwise, create the ref
    else this.portalConfigRef = FirebaseWorker.ref(`photoheight/company_space/${utilityCompany}/portal_config`);

    // Establish the listener
    this.portalConfigRef.on('value', (s) => {
      this.portalConfig = s.val();
    });
  }

  logOverviewLoaded() {
    if (this.user?.uid) {
      const logObject = {
        uid: this.user.uid,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
        desc: `Pole Overview loaded by ${this.user.email}`
      };
      FirebaseWorker.ref('photoheight/logs').push(logObject);
    }
  }

  openApp(e) {
    window.open(this.getAppUrl(e.currentTarget.key));
  }

  openRegionalAdminDialog(e) {
    this.shadowRoot.querySelector('#regionalAdminDialog').open();
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  setDefaultSortAttribute(default_sort_attribute) {
    if (default_sort_attribute) {
      this.sortAttr = default_sort_attribute;
    }
  }

  showCreateButton(approvedToSubmit, allowCreateBeforeApproved) {
    return approvedToSubmit || allowCreateBeforeApproved;
  }

  toggleAppRow(e) {
    this.set(
      `sortedFilteredJobRefs.${e.model.index}.$display.userRowExpanded`,
      !this.sortedFilteredJobRefs?.[e.model.index]?.$display?.userRowExpanded
    );
    this.updateAppRowExpanded(e.model.index);
  }

  updateApprovedToSubmit() {
    this.approvedToSubmitDebounce = Debouncer.debounce(this.approvedToSubmitDebounce, timeOut.after(100), async () => {
      if (!this.signedIn || !this.userGroup) return;

      let pendingCompany = null;
      if (!this.userCanRead) {
        this.aprovedToSubmit = false;
        pendingCompany = true;
        this.pendingApprovalText = `You must be approved to join ${await FirebaseWorker.ref(`photoheight/companies/${this.userGroup}/name`)
          .once('value')
          .then((s) => s.val())} before you can create applications`;
      } else {
        this.approvedToSubmit =
          ToArray(this.attacherList).some((company) => !company.pending && !company.pending_join_company) ||
          this.userGroup === this.utilityCompany;
        if (!this.approvedToSubmit) {
          pendingCompany = ToArray(this.attacherList).find((company) => company.pending || company.pending_join_company);
          if (pendingCompany) {
            if (pendingCompany.pending) {
              if (pendingCompany.$key === this.userGroup)
                this.pendingApprovalText = `Your company, ${pendingCompany.name}, must be approved by ${this.get(
                  'config.firebaseData.name'
                )} before you can create applications`;
              else this.pendingApprovalText = `You must be approved by ${pendingCompany.name} before you can create applications`;
            } else if (pendingCompany.pending_join_company)
              this.pendingApprovalText = `You must be approved to join ${pendingCompany.name} before you can create applications`;
          }
        }
      }
      const reviewContractors = Object.keys(this.portalConfig?.user_options?.review_contractors ?? {});

      this.$.notApprovedDialog.opened = !this.approvedToSubmit && (!this.attacherListLoading || !this.userCanRead) && pendingCompany;
      this.$.needToRequestDialog.opened =
        !this.approvedToSubmit &&
        (!this.attacherListLoading || !this.userCanRead) &&
        !pendingCompany &&
        !reviewContractors.includes(this.userGroup);
    });
  }

  updateAppRowExpanded(jobsRefIndex) {
    const jobRefDisplayPath = `sortedFilteredJobRefs.${jobsRefIndex}.$display`;
    const jobRefDisplay = this.get(jobRefDisplayPath) ?? {};
    const rowExpanded = !!jobRefDisplay.userRowExpanded || jobRefDisplay.filterRowExpanded;

    if (rowExpanded !== jobRefDisplay.rowExpanded) {
      // If row is supposed to be opened, stop hiding collapse content and let the dom-if handler expand the row once content has been stamped.
      if (rowExpanded) this.set(`${jobRefDisplayPath}.showCollapseContent`, true);
      // Otherwise, start collapsing the row and let the collapse's transition end handler hide the content once the row is collapsed.
      else {
        this.set(`${jobRefDisplayPath}.animateExpand`, true);
        this.set(`${jobRefDisplayPath}.rowExpanded`, false);
        // Notify start iron list update loop.
        NotifyResizable('poleAppOverview', this.$.appList, 400);
      }
    }
  }

  showAllDrafts() {
    this.showDrafts = true;
  }

  showAllJobs() {
    this.loadingJobs = true;
    this.numJobsToShow = null;
  }

  showCostExport(systemAdmin, appName) {
    return systemAdmin && (appName || '').startsWith('lge-ku');
  }

  showInvoiceExport(userGroup, appName) {
    return (userGroup == 'katapult' || userGroup == 'ppl_attachments') && appName == 'ppl-kws';
  }

  showAccrualExport(userGroup, appName) {
    return userGroup == 'katapult' && appName == 'ppl-kws';
  }

  toArray(object) {
    return ToArray(object);
  }

  updateFilters(e) {
    const filters = e.detail.value;

    // Update any filters that have changed (cuts down on redundant updates)
    if (filters.searchString !== this.searchString) this.searchString = filters.searchString;
    if (filters.showArchived !== this.showArchived) this.showArchived = filters.showArchived;
    if (filters.showDrafts !== this.showDrafts) this.showDrafts = filters.showDrafts;
    if (filters.showCompletedApps !== this.showCompletedApps) this.showCompletedApps = filters.showCompletedApps;
    if (!DeepEqual(filters.filterAttributeValues, this.filterAttributeValues)) this.filterAttributeValues = filters.filterAttributeValues;
  }

  computeFilterOnAdminStrategy(userGroup, utilityCompany, adminStrategy) {
    const isUtility = userGroup === utilityCompany;
    const hasAdminStrategy = ['REGION', 'ATTACHER', 'REGION_AND_ATTACHER'].includes(adminStrategy);
    return isUtility && hasAdminStrategy;
  }
}

window.customElements.define(PoleApplicationOverview.is, PoleApplicationOverview);
